.product_related {
  border-bottom: 1px solid #dee2e6;
  padding: 15px 0;
  &:last-child {
    border-bottom: none;
  }
  h3 {
    line-height: 1.1;
    font-size: 18px;
    font-weight: normal;
    a {
      color: $text-blue;
    }
  }
  .price {
    color: $text-blue;
    font-size: 20px;
  }
}
.images_cont {
  position: relative;
  .nav_arrows {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    left: -15px;
    right: -15px;
    z-index: $zindex-dropdown;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    button {
      pointer-events: initial;
      color: $text-light;
      opacity: 1;
      transition: opacity 0.3s;
      &:disabled {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
.products_list {
  h1.underlined {
    font-size: 28px;
    max-width: 90%;
    padding-bottom: 5px;
  }
}
.product_cart {
  img {
    max-height: 100px;
  }
  .img_container {
    width: 100%;
    height: 100px;
    background-size: contain;
    background-clip: border-box;
    background-repeat: no-repeat;
    background-position: center;
  }
  h5 {
    line-height: 1.1;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    color: $text-blue;
  }
  small {
    color: $text-light;
  }
  .price {
    color: $text-blue;
    .regluar_price {
      display: block;
      font-size: 80%;
      color: $text-light;
    }
  }
  .cart_price {
    font-size: 1.3em;
    font-weight: lighter;
  }
  .details {
    color: $text-light;
    display: block;
    font-size: 12px;
    font-weight: lighter;
    b {
      color: $text-default;
    }
  }
}

.product_block_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.product_block {
  max-width: 50%;
  margin-bottom: 20px;
  .brand {
    display: block;
    color: $text-light;
    font-size: 14px;
    font-weight: lighter;
  }
  .desc {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    & > * {
      flex-grow: 1;
    }
  }
  .price {
    display: flex;
    font-weight: 100;
    color: $text-blue;
    font-size: 25px;
    align-items: center;
    span:last-child {
      color: $text-light;
      font-size: 18px;
      margin-left: 5px;
    }
  }
}
.discount_img {
  position: absolute;
  text-align: center;
  pointer-events: none;
  z-index: 300;
  max-width: 90%;
  left: 50%;
  transform: translate(-50%);
}
.product_block_list {
  width: 255px;
  position: relative;
  .brand {
    display: block;
    color: $text-light;
    font-size: 14px;
    font-weight: lighter;
  }

  .desc {
    display: flex;
    flex-direction: column;
    & > * {
      flex-grow: 1;
    }
  }
  .price {
    font-weight: 100;
    margin: 10px 0;
    color: $text-blue;
    font-size: 20px;
    align-items: flex-end;
    span {
      &.unit {
        opacity: 0.7;
        font-size: 70%;
        margin-left: 5px;
      }
      &.regluar_price {
        color: $text-light;
        font-size: 20px;
        font-size: 70%;
        display: block;
        span {
          &:first-child {
            text-decoration: line-through;
          }
        }
      }
    }
  }
  .surface_data {
    & > span {
      display: block;
      &:not(:last-child) {
        &::after {
          content: none;
        }
      }
    }
  }
  .surface_info {
    text-align: center;
    .amount_data {
      display: none;
    }
  }
}

.no_stock {
  text-align: center;
  color: $text-light;
}
.surface_data {
  .badge_main {
    padding: 10px 15px;
    font-size: 13px;
    &.bold {
      font-weight: 600;
      background-color: #ffed5f;
      font-size: 15px;
    }
  }
  & > span {
    font-weight: normal;
    &:not(:last-child) {
      margin-right: 15px;
    }
    span {
      font-size: 12px;
      margin-left: 3px;
      opacity: 0.7;
    }
  }
}
