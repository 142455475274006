html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: 'Montserrat', 'Roboto', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  /* Margin bottom by footer height */
  margin-bottom: $footer-height;
  width: 100vw;
  overflow-x: hidden;
  min-height: 90vh;
  & .main_container {
    padding: 60px 15px 0;
  }
  .contanier {
    overflow-x: hidden;
  }
}
.first-container {
  margin-top: 95px;
}
.last-container {
  padding-bottom: 100px;
}
main {
  margin-top: 56px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px 0;
  /* Set the fixed height of the footer here */
  height: $footer-height;
  background-color: $secondary-color;
  min-height: 310px;
  color: #bed8f3;
  .items-cont {
    padding: 25px 10px;
  }
  a.nav-link {
    color: #bed8f3;
  }
  a.nav-link:hover {
    color: #fff;
  }
  .footer-info {
    font-size: 1.2em;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    color: white;
  }
  .footer-info ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .footer-info li > a,
  .footer-info p {
    background: transparent;
    border: 0;
    color: #ffffff;
    font-weight: 300;
    opacity: 0.7;
    font-size: 80%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }
  .social_media a {
    color: #fff;
  }
  .footer-info h3,
  .footer-info h3 a {
    color: white;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.625rem;
  }
  .bottom_footer {
    width: 100%;
    background: $main-color-dark;
    padding: 20px 0;
    .container {
      display: flex;
      justify-content: space-between;
    }
    a {
      color: $text-lighter;
      font-size: 25px;
      margin-left: 15px;
    }
    .legal {
      a {
        font-size: 15px;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.display_none {
  display: none;
}

.bee_track {
  width: 100%;
  background-color: #fbca26;
}
.bee_track_img {
  width: 100%;
  background-color: #00aec3;
}
@media (max-width: 575px) {
  .first-container {
    margin-top: 66px;
  }
  .footer {
    .bottom_footer {
      .container {
        flex-direction: column;
        text-align: center;

        .legal {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
